<template>
    <div class="modal-container" tabindex="0" ref="modal" @click="handleContainerClick">
        <div class="modal">
            <button class="btn close-modal" v-if="closeModal" @click="closeModal">X</button>
            <slot></slot>
        </div>
    </div>
</template>

<script>
    export default {
        props: ['closeModal'],
        mounted() {
            this.$refs.modal.focus();
        },
        methods: {
            handleContainerClick($evt) {
                if (this.closeModal && $evt.currentTarget === $evt.target) {
                    this.closeModal();
                }
            },
        },
    };
</script>

<style lang="scss" scoped>
    .modal-container {
        position: fixed;
        display: flex;
        top: 0;
        left: 0;
        height: 100vh;
        width: 100vw;
        justify-content: center;
        align-items: center;
        z-index: 1000;
        backdrop-filter: blur(5rem) grayscale(80%);
    }

    .modal {
        position: relative;
        background: var(--color-white);
        display: block;
        padding: var(--pad-medium);
        padding-top: 1.5rem;
        border-radius: 3px;
        min-height: 15rem;
        min-width: 15rem;
        overflow: auto;
        max-width: 90%;
        max-height: 90vh;
        z-index: 1000;
        resize: both;
        border: 1px solid var(--color-black);
    }

    .close-modal {
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 1.7rem;
        width: 1.7rem;
        top: 0;
        right: 0;
        padding: var(--pad-small);
        font-weight: bold;
        border: none;
    }
</style>
